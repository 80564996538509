@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap');

body{
    margin: 0;
    padding:0;
    font-family: "Open Sans", sans-serif;
}
table {
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
}
table > thead > tr > th {
    text-align: left;
    padding: 0.5rem 0.5rem;
    background-color: #efefef;
}

table > tbody > tr > td {
    padding: 0.5rem 0.5rem;
    border-bottom: 1px solid #efefef;
    white-space: nowrap;
}

.animated {
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.fadeInDown {
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
}

@keyframes fadeInDown {
    0% {
        opacity: 0;
        transform: translateY(-20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
@tailwind base;
@tailwind components;
@tailwind utilities;